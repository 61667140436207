import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPostDentalServices: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const overview_img = data.overview_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>7 Services That Your Dentist Should Provide</title>
      <meta name="description" content="There are many issues that might trigger tooth pain or need dental treatment. Here are 7 dental services including cleanings, fillings, crowns, root canals, dental implants, dentures and more." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="There are many issues that might trigger tooth pain or need dental treatment. Here are 7 dental services including cleanings, fillings, crowns, root canals, dental implants, dentures and more." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="There are many issues that might trigger tooth pain or need dental treatment. Here are 7 dental services including cleanings, fillings, crowns, root canals, dental implants, dentures and more." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Dental Services
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2022/02/10
                </li>
                <li className="list-inline-item badge badge-primary">
                  Dental Services
                </li>
                <li className="list-inline-item badge badge-secondary">
                  7 Services That Your Dentist Should Provide
                </li>
              </ul>
                <Img fluid={overview_img} className="blog-post-img"/>
              <h2 className="mb-3">
                7 Services That Your Dentist Should Provide
              </h2>
              <p>
                Dental treatment required at a dental clinic is determined by the condition of your teeth. There are a variety of issues that might trigger tooth pain or need dental treatment.
              </p>
              <p>
                An oral examination and a series of dental X-Rays are the first stages of a dentist's work. It is at this point that the dentist will sit down with the patient and go through what kind of dental treatment is required. It is important to choose a good dentist that is the right fit for you. Additionally, you should see a dentist that is experienced, knowledgeable, keeps up on current trends, has state-of-the-art technology, and is good at communication.
              </p>
              <p>
                The following are a list of procedures that you will want your general dentist to be able to perform when you visit the dentist.
              </p>
              <h3 className="mb-3">
                Check Up & Cleaning
              </h3>
              <p>
                One of the most important reasons to see a dentist is to get a bi-annual check up. During this time the dentist will check to see if you have any problems developing. Included in this is an oral cancer screening. Oral cancer is usually very easy to treat when it is caught and treated early.
              </p>
              <p>
                Additionally, you should have a cleaning by a dentist or dental hygienist at that time.
              </p>
              <h3 className="mb-3">
                Fillings
              </h3>
              <p>
                If there is a small hole on the surface caused by decay, the dentist will most often fill it with a composite or silver amalgam material. The filling material surrounds and seals the cavity, preventing it from growing. This is one of the most frequently used procedures for a painful or sensitive tooth with minor damage to the enamel.
              </p>
              <h3 className="mb-3">
                Dental Crowns
              </h3>
              <p>
                A crown, also known as a cap, is a one-time application that is put on top of the existing tooth. If the tooth is severely fractured, chipped, or rotten, a dental crown may be required. Crowns are used to strengthen teeth after a root canal procedure as well. The dentist will grind down the present tooth and place the crown over it before installing the cap. Porcelain, metal, or a combination of the two is used to make crowns.
              </p>
              <h3 className="mb-3">
                Root Canals
              </h3>
              <p>
                If a tooth is decayed down to the roots, it may be treated with root canal therapy. To achieve this, the pulp must be removed, and the innermost part of the tooth is sealed off to prevent bacteria from entering again. It is frequently advised to put a crown on the top of a tooth with a root canal since the tooth is weakening.
              </p>
              <h3 className="mb-3">
                Bridges & Dental Implants
              </h3>
              <p>
                These are two potential dentistry procedures to restore a missing tooth or teeth. A bridge is a false tooth set in place via neighboring teeth. The false tooth is in the middle of two crowns on the anchoring teeth, with a crown between them.
              </p>
              <p>
                Dental implants are artificial roots that are utilized to support replacement teeth. Typically made of a titanium post, implants are placed into your jawbone. After some time, when the bone has healed around the titanium implant, a dental crown will be placed on top to give you a natural looking tooth that functions almost identically to your natural teeth.
              </p>
              <h3 className="mb-3">
                Dentures
              </h3>
              <p>
                These are prosthetic teeth that have been made to replace missing teeth. Dentures are divided into two categories: full and partial dentures. Dentures are sometimes referred to as "false teeth."
              </p>
              <h3 className="mb-3">
                Extractions
              </h3>
              <p>
                In the event that one of the teeth is damaged or destroyed beyond repair, it is time to have the last option. Extractions are frequently less expensive than a crown or root canal. If the tooth can be saved and the cost can be absorbed by the patient, this process should not be performed. However, there are times when there is no other good option. Also, thankfully, there are procedures that can be performed afterwards that can restore your oral health. Though these procedures can be costly.
              </p>
              <p>
                If you are looking for any of the above listed services, please feel free to contact our office at 317-643-9434.
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogPostDentalServices

export const query = graphql`
  query BlogPostDentalServicesQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    overview_img: file(name: { eq: "dental-services" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
